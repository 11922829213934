<template>
  <div class="page" >
    <ls-title-header @back="cancel" title="添加设备" ></ls-title-header>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="140px"  class="demo-ruleForm">
      <div style="background-color: white">
        <div class="box">

          <el-row class="row">
            <el-col :span="24">
                <el-form-item prop="deviceId">
                  <template #label><span class="title">设备编号</span></template>
                  <el-input v-model="ruleForm.deviceId" placeholder="请输入设备ID" @input="deviceChange"  @change="deviceAuth" onkeyup="value=value.replace(/[^\a-\z\A-\Z0-9]/g,'')" maxlength="20" show-word-limit ></el-input>
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
          <el-col :span="24">
              <el-form-item prop="deviceId">
                <template #label><span class="title">设备名称</span></template>
                <span class="content">{{ruleForm.deviceName}}</span>
              </el-form-item>
          </el-col>
        </el-row>

        <el-row class="row">
            <el-col :span="24">
                <el-form-item prop="deviceId">
                  <template #label><span class="title">设备类型</span></template>
                  <span class="content">{{ruleForm.deviceType}}</span>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row class="row">
          <el-col :span="24">
              <el-form-item prop="deviceId">
                <template #label><span class="title">设备型号</span></template>
                <span class="content">{{ruleForm.deviceModel}}</span>
              </el-form-item>
          </el-col>
        </el-row>

          <el-row class="row">
            <el-col :span="24">
                <el-form-item prop="remarks">
                  <template #label><span class="title">信息备注</span></template>
                  <el-input v-model="ruleForm.remarks" placeholder="请输入信息备注" maxlength="50" show-word-limit></el-input>
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="24">
                <el-form-item>
                  <template #label><span class="title">绑定用户</span></template>
                  <el-input v-model="ruleForm.userName"  placeholder="请选择用户" class="input" disabled>
                    <template #append>
                      <el-button  @click="this.userPage = true"  type="primary" style="color: white" >选择用户</el-button>
                    </template>
                  </el-input>
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="24">
                <el-form-item>
                  <template #label><span class="title">管&ensp;理&ensp;人</span></template>
                  <el-input v-model="ruleForm.noticeName" placeholder="请选择管理人"  class="input" disabled>
                    <template #append>
                      <el-button @click="this.managerPage = true"  type="primary" style="color: white" >选择管理</el-button>
                    </template>
                    </el-input>
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="24">
                <el-form-item prop="positionName">
                  <template #label><span class="title">位置名称</span></template>
                  <el-input v-model="ruleForm.positionName" placeholder="请选择安装位置" class="input" >
                    <template #append>
                      <el-button @click="this.posPage = true" type="primary"  style="color: white">选择位置</el-button>
                    </template>
                  </el-input>
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="24">
              <el-form-item>
                <template #label><span style="color:#F56F6F">* </span><span class="title">安装地址</span></template>
                <ls-address style="display: flex;justify-content: space-between;width:100%;" @adr-change="getAddress" :province="this.address.province" :city="this.address.city" :district="this.address.district" :street="this.address.street"></ls-address>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="24">
              <el-form-item>
                <el-input v-model="this.address.detail" placeholder="请输入详细地址" maxlength="50" show-word-limit class="input"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="24">
              <el-form-item>
                <el-button type="primary" size="small" @click="submitForm('ruleForm')">提交</el-button>
              </el-form-item>
            </el-col>
          </el-row>

        </div>
      </div>
    </el-form>

    <template v-if="userPage">
      <MattressAddUser @update="updateUser" @cancel="this.userPage = false"></MattressAddUser>
    </template>
    <template v-if="managerPage">
      <MattressAddManager @update="updateManager" @cancel="this.managerPage = false"></MattressAddManager>
    </template>
    <template v-if="posPage">
      <MattressAddPos @update="updatePosData" @cancel="this.posPage = false"></MattressAddPos>
    </template>
  </div>
</template>

<script>
import {
  deviceAuth,
  addMattressInfo
} from '../../../../api/api'
import session from '../../../../store/store'
import util from '../../../../until/until'
import MattressAddUser from './MattressAddUser.vue'
import MattressAddManager from './MattressAddManager.vue'
import MattressAddPos from './MattressAddPos.vue'
export default {
  components: {
    MattressAddUser,
    MattressAddManager,
    MattressAddPos
  },
  data() {
    return {
      userPage:false,
      managerPage: false,
      posPage: false,
      ruleForm: {
        deviceId: '',
        deviceName:'',
        deviceType:'',
        deviceModel:'',
        deviceFuncId:'',
        deviceSubId:'',
        deviceUuid:'',
        remarks:'',
        idcard:'',
        userName:'',
        noticeUuid:'',
        noticeName:'',
        positionUuid:'',
        positionName:'',
        positionAddress:'',
      },
      address: {
          province:'',
          city:'',
          district:'',
          street:'',
          detail:'',
      },
      rules: {
        deviceId:[
          {required: true,message: "请输入设备号", trigger: "blur"},
        ],
        positionName:[
          {required: true,message: "请选择安装位置", trigger: "blur"},
        ],
      },
    };
  },
  methods: {
    deviceChange() {
      this.ruleForm.deviceName = ""
      this.ruleForm.deviceType = ""
      this.ruleForm.deviceModel = ""
      this.ruleForm.deviceFuncId = ""
      this.ruleForm.deviceSubId = ""
      this.ruleForm.deviceUuid = ""
    },
    deviceAuth() {
      var param = {
        deviceId:this.ruleForm.deviceId,
      }
      deviceAuth(param).then(res => {
        if (res.data.code == 200) {
          this.ruleForm.deviceName = res.data.dataInfo.name
          this.ruleForm.deviceType = res.data.dataInfo.type
          this.ruleForm.deviceModel = res.data.dataInfo.model
          this.ruleForm.deviceFuncId = res.data.dataInfo.funcId
          this.ruleForm.deviceSubId = res.data.dataInfo.subId
          this.ruleForm.deviceUuid = res.data.dataInfo.uuid
        } else {
          this.$message.error({ message: res.data.message});
          this.ruleForm.deviceId = ""
          this.ruleForm.deviceName = ""
          this.ruleForm.deviceType = ""
          this.ruleForm.deviceModel = ""
          this.ruleForm.deviceFuncId = ""
          this.ruleForm.deviceSubId = ""
          this.ruleForm.deviceUuid = ""
        }
      })
    },
    submitForm(ruleName) {
      this.$refs[ruleName].validate((valid) => {
        if (valid) {
          this.addMattressInfo();
        } else {
          return false;
        }
      });
    },
    addMattressInfo() {
      if (util.isNULL(this.address.detail)) {
        this.$message.error("请输入完整地址")
        return;
      }

      let address = this.address.province + '#' + this.address.city + '#' + this.address.district + '#' + this.address.street + '#' + this.address.detail;

      if(address == '####') {
        this.$message.error("请选择地址")
        return;
      }
      this.ruleForm.positionAddress = address
      this.ruleForm.customerUuid  = session.getCustomerUuid();
      this.ruleForm.unitUuid = session.getLoginInfo().unitUuid,
      
      addMattressInfo(this.ruleForm).then(res => {
        if (res.data.code == 200) {
          this.$message({ message: '新增成功',  type: 'success'});
          this.cancel();
          this.update();
        } else {
          this.$message.error({ message: res.data.message});
        }
      })
    },

    getAddress(data){
      this.address.province = data.selectProvince;
      this.address.city = data.selectCity;
      this.address.district =  data.selectDistrict;
      this.address.street = data.selectStreet
    },
    updateUser(data) {
      this.ruleForm.idcard = data.idcard;
      this.ruleForm.userName = data.name;
      this.ruleForm.positionName = data.name + '居住地'
      if(!util.isNULL(data.address)) {
        let addressArr = data.address.split("#");
        if(addressArr.length == 5) {
          this.address.province = addressArr[0];
          this.address.city = addressArr[1];
          this.address.district = addressArr[2];
          this.address.street = addressArr[3];
          this.address.detail = addressArr[4];
        }
      }
   
    },
    updateManager(data) {
      this.ruleForm.noticeUuid = data.uuid;
      this.ruleForm.noticeName = data.name;
    },
    updatePosData(data) {
      this.ruleForm.positionUuid = data.uuid;
      this.ruleForm.positionName = data.name;

      if(!util.isNULL(data.address)) {
        let addressArr = data.address.split("#");
        if(addressArr.length == 5) {
          this.address.province = addressArr[0];
          this.address.city = addressArr[1];
          this.address.district = addressArr[2];
          this.address.street = addressArr[3];
          this.address.detail = addressArr[4];
        }
      }
    },
    cancel() {
      this.$emit("cancel");
    },
    update() {
      this.$emit('update')
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  text-align: center;
  margin: 20px 0px;
}

// .input {
//   width: 50%;
// }
hr {
  border-color: #f4f4f526;
}
.page {
  padding: 0px 20px;
}
.el-form-item {
  margin-bottom: 0px !important;
  margin-left: -20px;
}
.row {
  margin: 20px 0px 20px 0px;
}
.box {
  padding: 20px;
  width: 75%;
  background-color: white;
}
.title {
  margin-right: 25px;
  font-size: 16px;
}

.content {
  font-size: 16px;
  margin-left: 8px;
}
</style>