<template>
  <div class="page" v-if="mainPage">
    <!--标题-->
    <ls-title title="智能床垫"></ls-title>

    <div class="conddiv">
      <el-input v-model="condition" placeholder="设备ID" prefix-icon="el-icon-search" style="width:200px;"></el-input>
      <!-- <el-select style="margin-left:5px;" v-model="deviceState" placeholder="设备状态" size="large">
        <el-option v-for="item in deviceStateOption" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select> -->
      <el-select style="margin-left:5px;" v-model="deviceModel"  placeholder="设备型号" size="large" clearable>
        <el-option v-for="item in deviceModelOption" :key="item.uuid" :label="item.deviceModel" :value="item.deviceModel"></el-option>
      </el-select>
      <!-- <el-select style="margin-left:5px;" v-model="deviceState" placeholder="安装位置" size="large">
        <el-option v-for="item in deviceStateOption" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select> -->
      <el-button type="warning" style="margin-left:5px;" @click="search">搜索</el-button>
      <el-button v-if="this.role != 1" type="primary" style="float:right;" @click="add">添加设备</el-button>
    </div>

      <!-- 表格 -->
      <el-table :data="dataList" border style="width: 100%;border-radius: 10px;" :header-cell-style="{ background: '#285df0'}" :empty-text="emptyText">
        <el-table-column prop="createTime" label="绑定日期" width="140" label-class-name="tablehead" align="center " />
        <el-table-column v-if="this.role == 1" prop="unitName" label="所属机构" label-class-name="tablehead" align="center" />
        <el-table-column prop="deviceId" label="设备ID" width="150" label-class-name="tablehead" align="center " />
        <el-table-column prop="deviceName" label="设备名称" width="130" label-class-name="tablehead" align="center " />
        <el-table-column prop="deviceModel" label="设备型号"  width="100" label-class-name="tablehead" align="center " />
        <el-table-column v-if="this.role != 1" prop="positionName" label="安装位置" label-class-name="tablehead" align="center " />
        <el-table-column prop="onLine" label="设备状态" width="100" label-class-name="tablehead" align="center" >
          <template #default="scope">
              <span v-if="scope.row.onLine==1" style="color:green">在线</span>
              <span v-if="scope.row.onLine==0" style="color:red">离线</span>
            </template>
        </el-table-column>
       <!--  <el-table-column v-if="this.role != 1" prop="elec" label="设备电量" label-class-name="tablehead" align="center " >
          <template #default="scope">
            <span v-if="scope.row.elec == 0">--</span>
            <span v-if="scope.row.elec != 0">{{scope.row.elec}}%</span>
          </template>
        </el-table-column> -->
        <el-table-column v-if="this.role != 1" prop="userName"  width="80" label="绑定用户" label-class-name="tablehead" align="center " />
        <!-- <el-table-column v-if="this.role != 1" prop="address" label="管理人" label-class-name="tablehead" align="center " /> -->
        <el-table-column label="操作" label-class-name="tablehead" align="center " width="100">
          <template #default="scope">
            <span class="textbtn" @click="seeMattress(scope.row)">管理</span>
            <span v-if="this.role != 1"> / </span>
            <span v-if="this.role != 1" class="textbtn" @click="deleteMattress(scope.row)">删除</span>
          </template>
        </el-table-column>
      </el-table>

    <div class="pagination">
      <ls-pagination :currentPage="currentPage" :page-size="size" :total="total" @size-change="handleSizeChange" @cur-change="handleCurrentChange"></ls-pagination>
    </div>
  </div>

  <template v-if="addPage">
    <MattressAdd @update="update" @cancel="cancelAdd"></MattressAdd>
  </template>
  <template v-if="detailsPage">
    <MattressDetails ref="childView" @cancel="cancelDetails"></MattressDetails>
  </template>
</template>

<script>
import {
    getDeviceModelInfos,
    getMattressInfos,
    deleteMattressInfo
} from '../../../../api/api'
import session from "../../../../store/store";
import until from "../../../../until/until";
import MattressAdd from './MattressAdd.vue'
import MattressDetails from './MattressDetails.vue'
export default {
  components: {
    MattressAdd,
    MattressDetails
  },
  data() {
    return {
      emptyText:'加载中',
      mainPage:true,
      detailsPage: false,
      addPage: false,
      role: 0,
      condition: "",//姓名
      // deviceState:'',//设备状态
      // deviceStateOption:[
      //   {value: 2,label: '全部'},
      //   {value: 1,label: '在线'},
      //   {value: 0,label: '离线'}
      // ],
      deviceModel:'',//设备型号
      deviceModelOption:[],
      currentPage: 1,
      size: 10,
      total: 0,//总数
      dataList: []
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.role = session.getLoginInfo().accountType;
      this.getMattressInfos();
      this.getDeviceModelInfos()
    },
    getDeviceModelInfos() {
      let param = {
        productType:2
      }
      getDeviceModelInfos(param).then(res => {
        if (res.data.code == 200) {
          this.deviceModelOption = res.data.dataInfo;
        }
      })
    },
    search() {
      this.getMattressInfos();
    },
    update() {
      this.getMattressInfos();
    },
    getMattressInfos() {
      let param = {
        unitUuid:session.getLoginInfo().unitUuid,
        role:this.role,
        page:this.currentPage - 1,
        size:this.size,
        deviceId:this.condition,
      }
      // if (!until.isNULL(this.deviceState)) {
      //   param.onLine = this.deviceState
      // }
      if (!until.isNULL(this.deviceModel)) {
        param.deviceModel = this.deviceModel
      }
      getMattressInfos(param).then(res => {
        if (res.data.code == 200) {
          this.total = res.data.count;
          this.dataList = res.data.dataInfo;
        }else {
          this.total = 0;
          this.dataList.splice(0, this.dataList.length);
          this.emptyText = "暂无数据"
        }
      })
    },
    add() {
      this.mainPage = false;
      this.addPage = true;
    },
    cancelAdd() {
      this.mainPage = true;
      this.addPage = false;
    },
    seeMattress(row) {
      this.mainPage = false;
      this.detailsPage = true;
      setTimeout(() => {
        this.$refs.childView.getParentData(row);
      }, 200);
    },
    cancelDetails() {
      this.mainPage = true;
      this.detailsPage = false;
    },
    deleteMattress(row) {
      this.$confirm('确定删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var param = {
            unitUuid:session.getLoginInfo().unitUuid,
            deviceId:row.deviceId,
          }
          deleteMattressInfo(param).then(res => {
            if (res.data.code == 200) {
              this.$message({ message: '删除成功',  type: 'success'});
              this.getMattressInfos();
            } else {
              this.$message.error({ message: res.data.message});
            }
          })
        }).catch(() => {});
    },

      //分页
    handleSizeChange(val) {
      this.size = val;
      this.getMattressInfos();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getMattressInfos();
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0px 20px;
}
.conddiv {
  background-color: white;
  border-radius: 10px;
  padding: 15px 20px;
  box-shadow: 0px 2px 3px 4px var(--el-color-info-light);
  margin-bottom: 20px;
}
.pagination {
  background-color: white;
  text-align: center;
  padding: 20px 0px;
  margin-top: 20px;
  border-radius: 10px;
}
.textbtn {
  color: #285df0;
  cursor: pointer;
}
</style>
<style>
.tablehead {
  color: white;
}
</style>