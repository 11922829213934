<template>
  <div class="page">
    <ls-title-header @back="cancel" title="设备管理"></ls-title-header>
    <div class="box">
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="设备信息" name="first">
          <MattressBaseInfo ref="baseView" ></MattressBaseInfo>
        </el-tab-pane>
        <el-tab-pane label="功能设置" name="second">
          <MattressWarning ref="setView"></MattressWarning>
        </el-tab-pane>
        <el-tab-pane label="使用记录" name="third">
          <MattressRecord ref="recordView"></MattressRecord>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import MattressBaseInfo from "./MattressBaseInfo.vue";
import MattressRecord from "./MattressRecord.vue";
import MattressWarning from "./MattressWarning.vue";
export default {
  components: {
    MattressBaseInfo,
    MattressRecord,
    MattressWarning,
  },
  data() {
    return {
      activeName: "first",
      mattressData:null,
    };
  },
  methods: {
    getParentData(data) {
      this.mattressData = data;
      setTimeout(() => {
        this.$refs.baseView.getParentData(this.mattressData);
      }, 200);
    },
    cancel() {
      this.$emit("cancel");
    },
    handleClick(tab) {
      if(0 == tab.index) {
        setTimeout(() => {
          this.$refs.baseView.getParentData(this.mattressData);
        }, 200);
      }else if(1 == tab.index) {
        setTimeout(() => {
          this.$refs.setView.getParentData(this.mattressData);
        }, 200);
      }else if(2 == tab.index) {
        setTimeout(() => {
          this.$refs.recordView.getParentData(this.mattressData);
        }, 200);
      }
    },
  },
};
</script>

<style scoped>
.page {
  padding: 0px 20px;
}
.box {
  /* width: 75%; */
  padding: 20px 20px;
  background-color: white;
  border-radius: 4px;
}
</style>
<style>
.el-tabs__item {
  font-size: 18px;
}

.el-tabs__nav-wrap::after {
  position: static !important;
}
</style>