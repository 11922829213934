<template>
  <div>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="140px" class="demo-ruleForm">
      <div style="background-color: white">
        <div class="box">

          <el-row class="row">
            <el-col :span="24">
                <el-form-item prop="name">
                  <template #label><span class="title">预警开关</span></template>
                  <el-switch v-if="edit == true" v-model="deviceData.switchOn" active-text="开" inactive-text="关"></el-switch>
                  <span v-show="edit == false" class="content" v-if="this.ruleForm.switchOn == 1">开启</span>
                  <span v-show="edit == false" class="content" v-if="this.ruleForm.switchOn == 0">关闭</span>
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="24">
                <el-form-item prop="name">
                  <template #label><span class="title">离床监控</span></template>
                  <!-- <el-time-picker  v-if="edit == true" v-model="this.rangeTime"  is-range range-separator="-"  start-placeholder="开始时间"  end-placeholder="结束时间"  format="HH:mm" value-format="HH:mm" style="width: 50%;"/> -->
                  <div style="width:50%;align-content: space-between;">
                    <el-time-picker arrow-control v-if="edit == true" v-model="deviceData.rangeStartTime" format="HH:mm" placeholder="睡眠时间" ></el-time-picker>
                    <span v-if="edit == true"> - </span>
                    <el-time-picker arrow-control v-if="edit == true" v-model="deviceData.rangeEndTime" format="HH:mm" placeholder="起床时间" ></el-time-picker>
                  </div>
                  <span v-show="edit == false" class="content" >{{deviceData.rangeTime}}</span>
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="24">
                <el-form-item prop="name">
                  <template #label><span class="title">离床预警</span></template>
                  <el-input v-show="edit == true"  v-model="ruleForm.outBedTime" placeholder="请输入离床预警时间" type="number" class="input">
                    <template #append>分钟</template></el-input>
                  <span v-show="edit == false" class="content" >{{ruleForm.outBedTime}}分钟</span>
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="24">
                <el-form-item prop="name">
                  <template #label><span class="title">心&emsp;&emsp;率</span></template>

                  <div class="input" style="display:flex; justify-content: space-between;">
                    <el-input v-show="edit == true" v-model="ruleForm.minHeartRate" placeholder="" type="number" style="width:45%;" ></el-input>
                    <span v-show="edit == true" style="margin:0px 10px;color:#909399;">--</span>
                    <el-input  v-show="edit == true" v-model="ruleForm.maxHeartRate" placeholder="" type="number" style="width:45%;" ></el-input>
                  </div>
                  <span v-show="edit == false" class="content" >{{ruleForm.minHeartRate}}~{{ruleForm.maxHeartRate}}</span>
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="24">
                <el-form-item prop="name">
                  <template #label><span class="title">呼&ensp;吸&ensp;率</span></template>
                  <div class="input" style="display:flex; justify-content: space-between;">
                    <el-input v-show="edit == true" v-model="ruleForm.minRespiratoryRate"  placeholder="" type="number" style="width:45%;"></el-input>
                    <span v-show="edit == true" style="margin:0px 10px;color:#909399;">--</span>
                    <el-input v-show="edit == true" v-model="ruleForm.maxRespiratoryRate" placeholder=""  type="number" style="width:45%;" > </el-input>
                  </div>
                  <span v-show="edit == false" class="content">{{ruleForm.minRespiratoryRate}}~{{ruleForm.maxRespiratoryRate}}</span>
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="24">
              <el-form-item>
                <el-button v-show="(edit == false)&&(this.role != 1)" type="primary" size="small" @click="edit = true" >编辑</el-button >
                <el-button v-show="edit == true" type="primary" size="small"  @click="edit = false" plain >取消</el-button>
                <el-button type="primary"  size="small" v-show="edit == true" @click="submitForm()">提交</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import {
  getMattressSetInfo,
  editMattressSetInfo
} from '../../../../api/api'
import session from "../../../../store/store";
export default {
  data() {
    return {
      edit: false,
      role: 0,
      deviceData:{
        switchOn:true
      },
      ruleForm: {},
      rules: {},
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    getParentData(data) {
      this.deviceData = data;
      this.getMattressSetInfo()
    },
    init() {
      this.role = session.getLoginInfo().accountType;
    },
    getMattressSetInfo() {
      let param = {
        deviceId:this.deviceData.deviceId,
        terminal:1,
        unitUuid:session.getLoginInfo().unitUuid
      }
      getMattressSetInfo(param).then(res => {
        if (res.data.code == 200) {
          this.ruleForm = res.data.dataInfo
          this.toData(res.data.dataInfo)
        }else {
          this.$message.error({ message: res.data.message});
        }
      })
    },
    submitForm() {
      this.editMattressSetInfo()
    },

    editMattressSetInfo() {
      this.ruleForm.deviceId = this.deviceData.deviceId
      this.ruleForm.terminal = 1
      this.ruleForm.unitUuid = session.getLoginInfo().unitUuid

      this.toEditData()
      editMattressSetInfo(this.ruleForm).then(res => {
        if (res.data.code == 200) {
          this.$message.success({ message: "修改成功"});
          this.closeEdit();
          this.getMattressSetInfo()
        }else {
          this.$message.error({ message: res.data.message});
        }
      })
    },

    toData(data) {
      if(data.switchOn == 1) {
        this.deviceData.switchOn = true
      }else {
        this.deviceData.switchOn = false
      }

      var rangeBeginHour = this.concatZero(data.rangeBeginHour);
      var rangeBeginMinute = this.concatZero(data.rangeBeginMinute);
      var rangeEndHour = this.concatZero(data.rangeEndHour);
      var rangeEndMinute = this.concatZero(data.rangeEndMinute);

      this.deviceData.rangeStartTime = new Date()
      this.deviceData.rangeStartTime.setHours(data.rangeBeginHour)
      this.deviceData.rangeStartTime.setMinutes(data.rangeBeginMinute)

      this.deviceData.rangeEndTime = new Date()
      this.deviceData.rangeEndTime.setHours(data.rangeEndHour)
      this.deviceData.rangeEndTime.setMinutes(data.rangeEndMinute)

      this.deviceData.rangeTime = rangeBeginHour + ":" + rangeBeginMinute + "~" + rangeEndHour + ":" + rangeEndMinute
    },

    toEditData() {
      if(this.deviceData.switchOn == true) {
        this.ruleForm.switchOn = 1
      }else {
        this.ruleForm.switchOn = 0
      }
      this.ruleForm.rangeBeginHour = this.deviceData.rangeStartTime.getHours()
      this.ruleForm.rangeBeginMinute = this.deviceData.rangeStartTime.getMinutes()

      this.ruleForm.rangeEndHour = this.deviceData.rangeEndTime.getHours()
      this.ruleForm.rangeEndMinute = this.deviceData.rangeEndTime.getMinutes()
    },
  
    closeEdit() {
      this.edit = false
    },
    concatZero(time) {
      var data = "00"
      if(time < 10) {
        data = '0' + time
      }else {
        data = time
      }
      return data
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  text-align: center;
  margin: 20px 0px;
}
.el-form-item {
  margin-bottom: 0px !important;
  margin-left: -19px;
}
.input {
  width: 50%;
}
hr {
  border-color: #f4f4f526;
}
.title {
  margin-right: 25px;
  font-size: 16px;
  color: #8D8D8D;
}
.box {
  width: 75%;
}
.content {
  font-size: 16px;
  margin-left: 10px;
}
.row {
  margin: 20px 0px 20px 0px;
}
</style>